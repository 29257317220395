import axios from "@/axios.js"

export default {
    fetchCoupon({commit},page){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/coupons/index?page=${page}`)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    // updateCoupon({commit},payload){
    //     return new Promise((resolve,reject)=>{
    //         axios.post(`/${payload.get('id')}`,payload)
    //         .then((response)=>{
    //             resolve(response)
    //         }).catch((error)=>{
    //             reject(error)
    //         })
    //     })
    // },

    createCoupon({commit},payload){
        return new Promise((resolve,reject)=>{
      
            axios.post('api/dashboard/coupons/generate',payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    deleteCoupon({commit},id){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/coupons/delete/${id}`)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
}