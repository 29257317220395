<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <!-- //Edit POP UP -->
    <div class="demo-alignment">
      <vs-popup
        class="holamundo"
        title="إنشاء كوبون"
        :active.sync="popupActive"
      >
        <div class="flex flex-col justify-items-center">
          <vs-checkbox class="mb-3" v-model="manual_code">
            هل تريد ادخال رمز الكوبون يدوياّ؟
          </vs-checkbox>
          <div class="vx-col w-1/2 w-full mb-2">
            <vs-input
              v-if="manual_code"
              type="text"
              class="w-full"
              label-placeholder="رمز الكوبون"
              v-model="current_coupon.code"
              data-vv-as="رمز الكوبون"
            />

            <vs-input
              type="number"
              v-if="!manual_code"
              class="w-full"
              label-placeholder="عدد الكوبونات المطلوبة"
              v-model="current_coupon.n_codes"
              data-vv-as="عدد الكوبونات"
              name="n_codes"
              v-validate="'required|numeric'"
            />
            <span class="text-danger text-sm" v-show="errors.has('n_codes')">
              {{ errors.first("n_codes") }}
            </span>
          </div>
          <div class="vx-col w-1/2 w-full mb-2 flex align-center">
            <vs-input
              type="number"
              class="w-4/5"
              label-placeholder="القيمة"
              v-model="current_coupon.discount"
              data-vv-as="القيمة"
              name="discount"
              v-validate="'required|numeric'"
            />
             <span class="text-danger text-sm" v-show="errors.has('discount')">
              {{ errors.first("discount") }}
            </span>
            <div class="w-1/5 m-3">
            <vs-label  class="vs-input--label" for="is_percentage"> نسبة مئوية؟</vs-label>
            <vs-checkbox
            id="is_percentage"
             v-model="current_coupon.is_percentage"
                
              >
              </vs-checkbox>
            </div>
           
          </div>
          <div class="vx-col w-1/2 w-full">
            <vs-input
              type="number"
              class="w-full"
              label-placeholder="عدد مرات الإستخدام"
              v-model="current_coupon.usage_count"
              data-vv-as="عدد مرات الإستخدام"
              name="usage_count"
              v-validate="'required|numeric'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('usage_count')"
            >
              {{ errors.first("usage_count") }}
            </span>
          </div>

          <div class="mb-6">&#8203;</div>
          <vs-button
            @click="submit()"
            color="success"
            type="filled"
            icon-pack="feather"
            icon="icon-check"
          >
            حفظ</vs-button
          >
        </div>
      </vs-popup>
    </div>
    <!-- ///////////////////// -->

    <vs-table
      ref="table"
      multiple
      v-model="selected"
      :max-items="itemsPerPage"
      search
      :data="coupons"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div
          class="flex flex-wrap-reverse items-center data-list-btn-container"
        >
          <!-- ACTION - DROPDOWN -->
          <vs-dropdown
            vs-trigger-click
            class="dd-actions cursor-pointer mr-4 mb-4"
          >
            <div
              class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full"
            >
              <span class="mr-2">العمليات</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon
                    icon="TrashIcon"
                    svgClasses="h-4 w-4"
                    class="mr-2"
                  />
                  <span>حذف</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon
                    icon="ArchiveIcon"
                    svgClasses="h-4 w-4"
                    class="mr-2"
                  />
                  <span>أرشيف</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon
                    icon="FileIcon"
                    svgClasses="h-4 w-4"
                    class="mr-2"
                  />
                  <span>طباعة</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon
                    icon="SaveIcon"
                    svgClasses="h-4 w-4"
                    class="mr-2"
                  />
                  <span>عمليات أخرى</span>
                </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <!-- ADD NEW -->
          <div
            @click="popupActive = true"
            class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
          >
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">إنشاء كوبون</span>
          </div>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown
          vs-trigger-click
          class="cursor-pointer mb-4 mr-4 items-per-page-handler"
        >
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                coupons.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : coupons.length
              }}
              of {{ queriedItems }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="code">رقم الكوبون</vs-th>
        <vs-th sort-key="value">القيمة</vs-th>
        <vs-th sort-key="created_at">تاريخ الإنشاء</vs-th>
        <vs-th sort-key="is_used">حالة الكوبون</vs-th>
        <vs-th sort-key="usage_count">الكوبونات المتبقية</vs-th>
        <vs-th>العمليات</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.code }}</p>
            </vs-td>

            <vs-td>
              <p v-if="tr.percentage == 0" class="product-category">{{ tr.value }}LYD</p>
              <p v-else class="product-category">{{ tr.value }}%</p>
            </vs-td>

            <vs-td>
              <vs-chip class="product-order-status">
                {{
                  `${new Date(tr.created_at).getFullYear()}-${
                    new Date(tr.created_at).getMonth() + 1
                  }-${new Date(tr.created_at).getDate()}`
                }}
              </vs-chip>
            </vs-td>

            <vs-td>
              <vs-chip
                :color="getCouponsStatusColor(tr.usage_count)"
                class="product-order-status"
                v-if="tr.usage_count >= 1"
              >
                {{ "فعال" }}</vs-chip
              >
              <vs-chip
                :color="getCouponsStatusColor(tr.usage_count)"
                class="product-order-status"
                v-else
              >
                {{ "منتهي" }}</vs-chip
              >
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.usage_count }}</p>
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <feather-icon
                icon="TrashIcon"
                svgClasses="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
                @click.stop="confirm(tr.id)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-pagination
      class="mt-4"
      :total="lastPage"
      v-model="current_Page"
      @change="getData(current_Page)"
    >
    </vs-pagination>
  </div>
</template>

<script>
import vSelect from "vue-select";
import moduleCoupon from "@/store/coupons/moduleCoupon.js";
export default {
  components: {
    vSelect,
  },
  data() {
    return {
      manual_code: false,
      selected: [],
      coupons: [],
      coupon_id: "",
      current_coupon: {
        is_percentage: false,
        code: null,
        n_codes: null,
        discount: null,
        usage_count: null,
      },
      current_Page: 1,
      itemsPerPage: 5,
      lastPage: 0,
      totalItems: 0,
      popupActive: false,
      loading: true,
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.coupons.length;
    },
  },
  methods: {
    getData(page) {
      this.$store.dispatch("coupon/fetchCoupon", page).then((response) => {
        this.coupons = response.data.coupons.data;
        this.itemsPerPage = response.data.coupons.per_page;
        this.totalItems = response.data.coupons.total;
        this.lastPage = response.data.coupons.last_page;
        this.$vs.loading.close();
      });
    },
    async submit() {
      let result = await this.$validator.validateAll();
      if (result) {
        var bodyFormData = new FormData();
        if(!this.manual_code){
          bodyFormData.set("code", "");
        }else{
          bodyFormData.set("code", this.current_coupon.code);
        }
        bodyFormData.set("percentage", this.current_coupon.is_percentage ? 1 :0);
        bodyFormData.set("n_codes", this.current_coupon.n_codes || 1);
        bodyFormData.set("discount", this.current_coupon.discount);
        bodyFormData.set("usage_count", this.current_coupon.usage_count);

        this.$store
          .dispatch("coupon/createCoupon", bodyFormData)
          .then(() => {
            this.popupActive = false;
            this.$vs.notify({
              title: "تم بنجاح",
              text: "تم تعديل الطلب بنجاح",
              color: "success",
            });
            this.getData(1);
          })
          .catch(() => {
            this.$vs.notify({
              title: "حدث خطأ",
              text: "يرجى اعادة المحاولة",
              color: "danger",
            });
          });
      }
    },
    confirm(id) {
      this.coupon_id = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "تأكيد",
        text: "هل انت متأكد من مسح هذا الكوبون؟",
        acceptText: "تأكيد",
        cancelText: "الغاء",
        accept: this.deleteData,
      });
    },
    deleteData() {
      this.$store
        .dispatch("coupon/deleteCoupon", this.coupon_id)
        .then(() => {
          this.getData(1);
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم مسح الكوبون بنجاح",
            color: "success",
          });
        })
        .catch(() => {
          this.$vs.notify({
            title: "حدث خطأ",
            text: "يرجى اعادة المحاولة",
            color: "danger",
          });
        });
    },

    getCouponsStatusColor(status) {
      if (status >= 1) return "primary";
      else return "danger";
    },
  },

  mounted() {
    this.$vs.loading();

    this.isMounted = true;
    this.getData();
  },
  created() {
    if (!moduleCoupon.isRegistered) {
      this.$store.registerModule("coupon", moduleCoupon);
      moduleCoupon.isRegistered = true;
    }
  },
};
</script>

<style lang="scss">
.align-center {
  align-items: center;
}
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
